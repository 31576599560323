function changeFont() {
    if (window.location.href.includes('dev_mode')) {
        const currentUrl = window.location.href;
        const urlSegments = currentUrl.split('/').filter(segment => segment !== '' && !segment.startsWith('?') && !segment.endsWith(':'));
        const isDetailPage = urlSegments.length >= 3;
        let message = `Шрифт на странице ${currentUrl} изменился на Jost`;

        try {
            document.documentElement.style.setProperty('--font-primary', "'Jost', Arial, sans-serif");

            if (isDetailPage) {
                const additionalMessage = '. А так же следующие настройки:\ncolor\nline-height\nletter-spacing\nfont-size';

                document.documentElement.style.setProperty('--font-color-primary', "#000000");
                document.documentElement.style.setProperty('--font-lh-primary', "180%");
                document.documentElement.style.setProperty('--font-ls-primary', "unset");

                message += additionalMessage

                //document.addEventListener('DOMContentLoaded', showJostFont)
            }

            console.log(message);
        } catch (e) {
            console.error('Шрифт не поменялся, возникла ошибка');
        }
    }
}

function showJostFont() {
    const elements = document.querySelectorAll('*');

    elements.forEach(element => {
        const style = window.getComputedStyle(element);
        const fontFamily = style.getPropertyValue('font-family');

        if (fontFamily.includes('Jost')) {
            element.style.color = `tomato`;
        }
    });
}

changeFont();